import { useEffect, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { Spinner } from 'components';
import { authContext } from 'helpers/services';


const PrivateRoute = (props: any) => {
    let location = useLocation();
    let authService = useContext(authContext);

    const returnUrl = `/account/login?returnUrl=${location.pathname}`;

    useEffect(() => {
        authService.getAuthenticatedUser();
    }, [])


    return (
        <>
            {authService.isLoading === true && <Spinner />}
            {authService.isLoading === false &&
                <>
                    {authService.isAuthenticated === false && <Redirect to={returnUrl} />}
                    {authService.isAuthenticated === true && <Route {...props} />}
                </>
            }
        </>
    );
}

export default PrivateRoute;