export let IT = {
    login_with_socials: "Accedi con un profilo social",
    login_with_credentials: "Accedi con il tuo account Vitec",
    email: "eMail",
    password: "Password",
    field_required: "{field} è obbligatorio",
    passwords_must_match: "Password e Conferma password non corrispondono",
    forgot_password: "Password dimenticata",
    login: "Login",
    logout: "Logout",
    not_registered: "Non sei registrato?",
    not_registered_subtitle: "Clicca sul pulsante sotto per creare un nuovo account.",
    register: "Registrati",
    firstname: "Nome",
    lastname: "Cognome",
    person_birthdate: "Data di nascita",
    save: 'Salva',
    send_code: 'Invia codice',
    code: 'Codice',
    reset_password: 'Resetta password',
    reset_password_subtitle: 'Per favore inserire il codice ricevuto via mail e la nuova password',
    reset_password_needed: "E' necessario reimpostare la password prima di procedere",
    newpassword: 'Nuova password',
    new_password_confirm: "Conferma la nuova password",
    capture: 'Capture.',
    share: 'Condividi.',
    site_terms: "Termini del sito",
    our_brands: "I nostri brand",
    privacy_policy: "Privacy Policy",
    cookies_policy: "Cookies Policy",
    privacy_url: "https://www.videndummediasolutions.com/it/privacy-policy/",
    supports: "Supporti",
    bags: "Zaini",
    camera_accessories: "Accessori per la tua fotocamera",
    audio_capture: "Dispositivi Audio",
    mobile_power: 'Mobile Power',
    video_transmission_systems: "Sistemi di trasmissione video",
    prompters: "Prompters",
    motion_control: "Motion Control",
    distribution_rental_services: "Distribuzione, Noleggi e Servizi",
    lighting_and_controls: "Luci e strumenti controllo luce",
    robotic_camera_systems: "Robotic camera systems",
    monitors: "Monitor",
    english: "Inglese",
    italian: "Italiano",
    privacy_read: "Ho letto, capito e accettato {link}",
    privacy_notice: "l'informativa sui dati personali",
    billing_country: "Nazione",
    password_confirm: "Conferma Password",
    register_account_title: "Registra nuovo account",
    saving: "Salvataggio",
    confirm: "Conferma",
    signup_confirm: "Conferma registrazione",
    signup_confirmed: "Il tuo account è ora valido, clicca su login per accedere al tuo account",
    verification_code_not_arrived: "Il codice di verifica non è arrivato?",
    click_on_button: "Clicca sul pulsante sotto per inviarne uno nuovo.",
    send: "Invia codice",
    continue_shopping: "Continua gli acquisti",

    info_data_saved: 'I tuoi dati sono stati aggiornati',
    info_verification_code_sent: 'Un codice di verifica è stato inviato nella tua email',
    info_password_changed: "Password modificata",
    info_account_not_found: "Il tuo account non è stato trovato.",

    err_password_mismatch: "Le password non coincidono",
    err_unable_to_update_account: "Impossibile aggiornare l'account",
    err_user_not_found: "Utente non trovato",
    err_check_privacy: "Devi selezionare il pulsante Privacy",
    err_must_set_email: "Devi settare un'email valida per inviare il codice di conferma.",
    err_unable_to_send_verification_code: "Si è verificato un errore durante l'invio del codice di verifica alla tua email.",
    err_unable_to_change_password: "Si è verificato un errore durante l'aggiornamento della password."
}