import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';


interface IUserContext {
    isLoading: boolean
    isSaving: boolean
    isSaved: boolean
    userNotFound: boolean
    userSchema: any
    user: User

    saveUser: (values: User) => Promise<void>
}

let userSchema = yup.object().shape({
    firstName: yup.string().default(''),
    lastName: yup.string().default(''),
    personBirthdate: yup.date().default(new Date())
})

type User = yup.InferType<typeof userSchema>;

export let userContext = React.createContext({} as IUserContext);

let { Provider } = userContext;

let UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { formatMessage } = useIntl();

    let [isLoading, setLoading] = useState(true);
    let [isSaving, setSaving] = useState(false);
    let [isSaved, setSaved] = useState(false);
    let [userNotFound, setNotFound] = useState(false);
    let [user, setUser] = useState<User>(userSchema.getDefault());

    useEffect(() => {
        getUser();
    }, [])


    let getUser = async () => {
        try {
            setNotFound(false);

            let res = await axios.get('/api/User/GetUser');
            let { item } = res.data;

            if (item) {
                setUser({
                    ...item,
                    personBirthdate: new Date(item.personBirthdate)
                });
            } else {
                setNotFound(true);
            }

        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    let saveUser = async (values: User) => {
        try {
            setSaving(true);
            await axios.post('/api/User/SaveUser', values);
            toast.success(formatMessage({ id: 'info_data_saved' }));
        } catch (err) { }
        finally {
            setSaving(false);
            setSaved(true);
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                isSaving,
                isSaved,
                userNotFound,
                userSchema,
                user,

                saveUser
            }}
        >
            {children}
        </Provider>
    )
}

export default UserProvider;