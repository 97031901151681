import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';


interface IAuthContext {
    currentUser?: User
    isLoading: boolean
    isAuthenticated: boolean

    federatedSignIn: (authority: 'Facebook' | 'Google') => Promise<void>
    signInWithCredentials: (clientId: string, clientSecret: string) => Promise<void>
    signInAndChangePassword: (clientId: string, clientSecret: string, newPassword: string) => Promise<boolean>
    forgotPassword: (email: string) => Promise<void>
    forgotPasswordSubmit: (clientId: string, code: string, password: string) => Promise<void>
    signUp: (email: string, password: string) => Promise<void>
    confirmSignUp: (username: string, code: string) => Promise<void>
    signOut: () => Promise<void>
    resendConfirm: (username: string) => Promise<void>
    getToken: () => Promise<string | null>
    getAuthenticatedUser: () => Promise<void>
}

export let authContext = React.createContext({} as IAuthContext);

let { Provider } = authContext;

let AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let history = useHistory();

    let [isLoading, setLoading] = useState(true);
    let [isAuthenticated, setAuthenticated] = useState(false);

    let [currentUser, setCurrentUser] = useState<User>();

    useEffect(() => {
        getAuthenticatedUser();
    }, [])


    Hub.listen("auth", async ({ payload: { event, data, message } }) => {
        switch (event) {
            case "signIn":
                await getAuthenticatedUser();
                history.push('/user');
                break;

            case 'oAuthSignOut':
                setAuthenticated(false);
                setLoading(false);
                break;

            case 'signIn_failure':
            case "signOut":
                setAuthenticated(false);
                setLoading(false);
                history.replace('/account/login');
                break;

            case "signUp":
                history.push('/account/signupconfirm', { email: data.user.username });
                break;

            case "signUp_failure":

                break;

            case 'forgotPassword':
                history.push('/account/resetpassword', { email: data.username });
                break;

            case 'forgotPasswordSubmit':
                history.replace('/account/login');
                break;

            default:
                setAuthenticated(false);
                setLoading(false);
                console.error(`[Profile] Not managed event: ${event}`);
                break;
        }
    });

    let signUp = async (email: string, password: string) => {
        try {
            await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email
                }
            });
        } catch (error: any) {
            toast.error(error.message);
            throw error;
        }
    }

    let resendConfirm = async (username: string) => {
        try {
            await Auth.resendSignUp(username);
        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let confirmSignUp = async (username: string, code: string) => {
        try {
            await Auth.confirmSignUp(username, code);
        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let signInWithCredentials = async (clientId: string, clientSecret: string) => {
        try {
            let cognitoUser = await Auth.signIn(clientId, clientSecret);

            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                history.replace('/account/changepassword', { clientId, clientSecret });
            }

        } catch (error: any) {
            toast.error(error.message);
        }
    }

    let federatedSignIn = async (provider: 'Facebook' | 'Google') => {
        try {
            //@ts-ignore
            await Auth.federatedSignIn({ provider });
        } catch (err: any) {
            toast.error(err.message);
        }
    }

    let signInAndChangePassword = async (clientId: string, clientSecret: string, newPassword: string) => {
        try {
            let cognitoUser = await Auth.signIn(clientId, clientSecret);
            await Auth.completeNewPassword(cognitoUser, newPassword);
            return true;

        } catch (error: any) {
            toast.error(error.message);
            return false;
        }
    }

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    let forgotPassword = async (userId: string) => {
        try {
            await Auth.forgotPassword(userId);
        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    // To verify attribute with the code
    let forgotPasswordSubmit = async (clientId: string, code: string, password: string) => {
        try {
            await Auth.forgotPasswordSubmit(clientId, code, password);
        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let signOut = async () => {
        try {
            await Auth.signOut();
        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let getToken = async () => {
        let session = await Auth.currentSession();

        if (session?.isValid())
            return session.getIdToken().getJwtToken();
        else
            return null;
    }


    let getAuthenticatedUser = async () => {
        try {
            setLoading(true);
            let cognitoUser = await Auth.currentAuthenticatedUser();
            let user = new User(cognitoUser);

            setAuthenticated(true);
            setCurrentUser(user);

        } catch (e) {
            setAuthenticated(false);
            setCurrentUser(undefined);
        } finally {
            setLoading(false);
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                isAuthenticated,
                currentUser,

                signUp,
                signInAndChangePassword,
                federatedSignIn,
                confirmSignUp,
                signInWithCredentials,
                forgotPassword,
                forgotPasswordSubmit,
                signOut,
                resendConfirm,
                getToken,
                getAuthenticatedUser
            }}
        >
            {children}
        </Provider>
    )
}

class User {
    readonly username: string;
    readonly email: string;

    constructor(cognitoUser: any) {
        this.username = cognitoUser.username;
        this.email = (cognitoUser.signInUserSession.idToken.payload['email']).toString().toLowerCase();
    }
}

export default AuthProvider;