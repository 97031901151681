import React, { useState, useEffect } from 'react';
import { KeyValuePair } from 'helpers';
import axios from 'axios';


interface IProps{
    children: React.ReactNode, 
    urlCallback: string
    footerClass: string
    footerLogo: string
}

interface IApplicationContext {
    isLoading: boolean
    currentCountry: string
    callbackUrl: string
    footerClass: string
    footerLogo: string
    referenceData: IReferenceData
    localizationCountries: KeyValuePair<string, string>[]

    chooseCountry: (country: string) => void
}

interface IReferenceData {
    countries: KeyValuePair<string, string>[]
    brands: KeyValuePair<string, string>[]
    preferredLanguages: KeyValuePair<string, string>[]
}

export let applicationContext = React.createContext({} as IApplicationContext);

let { Provider } = applicationContext;

let ApplicationProvider: React.FC<IProps> = ({ children, urlCallback, footerClass, footerLogo }) => {
    const defaultCountry = "XX";

    const localizationCountries = [
        new KeyValuePair<string, string>('XX', 'english'),
        new KeyValuePair<string, string>('IT', 'italian'),
    ]

    let [isLoading, setLoading] = useState(true);
    let [currentCountry, setCurrentCountry] = useState(defaultCountry);
    let [referenceData, setReferenceData] = useState({} as IReferenceData);


    useEffect(() => {
        initializeCountry();
        getReferenceData();
    }, []);


    let initializeCountry = () => {
        let storageCountry = localStorage.getItem(process.env.REACT_APP_COUNTRY_KEY || '');

        if (!storageCountry) {
            let headerCountry = window.navigator.language.substring(window.navigator.language.lastIndexOf('-') + 1);
            let country = localizationCountries.find(x => x.key === headerCountry);

            chooseCountry(country?.key || defaultCountry);
        } else {
            if (isValidCountry(storageCountry) === false)
                chooseCountry(defaultCountry);
        }
    }

    let isValidCountry = (country: string) => {
        return localizationCountries.findIndex(x => x.key === country) !== -1;
    }

    let chooseCountry = (country: string) => {
        setCurrentCountry(country);
    }

    let getReferenceData = async () => {
        try {
            setLoading(true);
            let res = await axios.get('/api/ReferenceData/GetReferenceData');
            setReferenceData(res.data.item);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    }


    return (
        <Provider value={{
            isLoading,
            currentCountry,
            callbackUrl: urlCallback,
            footerClass, 
            footerLogo, 
            referenceData,
            localizationCountries,

            chooseCountry
        }}>
            {children}
        </Provider >
    )
}

export default ApplicationProvider;