import { useContext } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory } from 'react-router';
import { authContext } from 'helpers/services';
import { Localization } from 'helpers/settings';
import { applicationContext } from './ApplicationContext';


let AxiosInterceptors = () => {
    let history = useHistory();

    let authService = useContext(authContext);
    let appService = useContext(applicationContext);

    axios.interceptors.request.use(async config => {
        if (authService.isAuthenticated) {
            let token = await authService.getToken();
            if (config.headers)
                config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    })

    axios.interceptors.response.use(
        response => response,

        error => {
            let { data, status } = error.response;
            switch (status) {
                case 400:
                    if (data.HasErrors) {
                        data.Errors.forEach((er: string) => {
                            let message = Localization[appService.currentCountry][er];
                            if (message)
                                toast.error(message);
                            else
                                toast.error(er);
                        })
                    } else {
                        for (let i in data.errors) {
                            let message = Localization[appService.currentCountry][data.errors[i]];
                            if (message)
                                toast.error(message);
                            else
                                toast.error(data.errors[i]);
                        }
                    }
                    break;

                case 401:
                    history.replace('/error401');
                    break;

                case 500:
                    toast.error('Internal server error');
                    break;

                default:
                    toast.error('Unable to connect to server, please check your internet connection and retry.');
                    break;
            }

            return Promise.reject({ ...data });
        }
    )

    return (null);
}

export default AxiosInterceptors;