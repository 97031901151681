import { useContext } from "react";
import { Spinner } from 'components';
import { useIntl } from 'react-intl';
import { userContext } from './UserContext';
import { Field, Form, Formik, ErrorMessage } from "formik";
import DatePicker from 'react-datepicker';
import { applicationContext } from "helpers/services";


let User = () => {
    let { formatMessage } = useIntl();

    let { callbackUrl } = useContext(applicationContext);
    let service = useContext(userContext);


    return (
        <div className="accountpanel">
            <div className="form-container p-5">
                <div className="container order-2 order-md-1 bg-white p-5">
                    <span className="brand-logo"></span>
                    {service.isLoading && <Spinner />}
                    {service.isLoading === false &&
                        <>
                            {service.userNotFound && <p className="alert alert-info">{formatMessage({ id: "info_account_not_found" })}</p>}
                            {service.userNotFound === false &&
                                <Formik
                                    enableReinitialize={true}
                                    validationSchema={service.userSchema}
                                    initialValues={service.user}
                                    onSubmit={async (value, action) => {
                                        action.setSubmitting(true);
                                        await service.saveUser(value);
                                        action.setSubmitting(false);
                                    }}
                                >
                                    {form =>
                                        <Form>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="firstName">{formatMessage({ id: "firstname" })}</label>
                                                    <Field id="firstName" className="form-control" name="firstName" />
                                                    <ErrorMessage name="firstName" component="span">
                                                        {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'firstname' }) })}</span>}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="lastName">{formatMessage({ id: "lastname" })}</label>
                                                    <Field id="lastName" className="form-control" name="lastName" />
                                                    <ErrorMessage name="lastName" component="span">
                                                        {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'lastname' }) })}</span>}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-6 pt-3">
                                                    <label htmlFor="personBirthdate">{formatMessage({ id: "person_birthdate" })}</label>
                                                    <DatePicker
                                                        selected={form.values.personBirthdate}
                                                        onChange={(date) => form.setFieldValue("personBirthdate", date)}
                                                        dateFormat={'yyyy/MM/dd'}
                                                        className="form-control"
                                                        placeholderText={formatMessage({ id: "person_birthdate" })}
                                                        maxDate={new Date()}
                                                    />
                                                    <ErrorMessage name="personBirthdate" component="span">
                                                        {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'personBirthdate' }) })}</span>}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="form-group mt-4">
                                                <button type="submit" className="btn btn-block btn-success w-100" disabled={service.isSaving}>
                                                    {formatMessage({ id: 'save' })}
                                                    {service.isSaving && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}
                                                </button>
                                            </div>
                                        </Form>
                                    }
                                </Formik>
                            }
                        </>
                    }

                    {service.isSaved &&
                        <div className="mt-4 alert alert-success">
                            {formatMessage({ id: 'info_data_saved' })}<br />
                            <a href={callbackUrl}>{formatMessage({ id: 'continue_shopping' })}</a>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default User;